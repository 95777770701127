@import "Themes/constants.scss";

.root {
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	height: 60px;
	background-color: var(--color-8);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
	z-index: 10;

	&[data-visible="false"] {
		display: none;
	}

	@media (max-width: $screen-m) {
		height: 100px;
	}
}

.root-container {
	display: flex;
	gap: 16px;
	justify-content: center;
	align-items: center;
	padding: 16px 22px;
	color: var(--color-1);
	font-family: var(--font-primary);
	font-size: 18px;
	font-weight: bold;
}

.wrongchain-text {
	line-height: 24px;
	margin: 0;
}
