@import "Themes/constants.scss";

.root {
	float: right;
	margin-right: 32px;
	line-height: 24px;
	display: flex;

	img {
		vertical-align: bottom;
		cursor: pointer;
	}
}

