@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@font-face {
    font-family: 'Road Rage';
    src: url('./fonts/RoadRage.woff2') format('woff2'),
        url('./fonts/RoadRage.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brightness Book';
    src: url('./fonts/BrightnessBook.woff2') format('woff2'),
        url('./fonts/BrightnessBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


