@import "../Base/classes.module.scss";
@import "Themes/constants.scss";

.header {
	display: grid;
	grid-template-columns: auto 1060px;
	min-height: 1060px;
	margin: auto;

	@media (max-width: 1900px) {
		grid-template-columns: auto 960px;
		min-height: 960px;
	}

	@media (max-width: 1600px) {
		grid-template-columns: auto 647px;
	}

	@media (max-width: 1400px) {
		grid-template-columns: auto 447px;
	}

	@media (max-width: $screen-m) {
		grid-template-columns: auto;
	}

	.minter-bg {
		// background-image: url("../../../assets/images/pages/home/background/rudy-background-large.png");
		// background-size: cover;
		// background-position: top center;
		display: flex;
		align-items: center;
		justify-content: space-around;
		overflow: hidden;

		> video {
			width: 100%;
		}

		@media (max-width: 1600px) {
			> video {
				height: 100%;
				width: unset;
			}
		}

		@media (max-width: $screen-m) {
			min-height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: -1;

			> video {
				height: 100%;
				width: unset;
			}
	
		}
	}

	.minter-flex {
		display: flex;
		align-items: center;
		justify-content: space-around;

		.minter-container {
			margin: 0 auto 200px auto;
			width: 100%;
			max-width: 650px;
			text-align: center;

			@media (max-width: 1900px) {
				margin: 0 auto 100px auto;
			}

			@media (max-width: $screen-m) {
				margin: 320px 100px 200px 100px;
			}

			@media (max-width: $screen-s) {
				margin: 320px 30px 200px 30px;
			}

			.minter {
				border: 7px solid var(--color-1);
				box-shadow: 15px 15px var(--color-1);
				background-color: var(--color-4);

				h1 {
					font-family: var(--font-secondary);
					font-weight: 400;
					font-size: 64px;
					line-height: 70px;
				}

				.minter-coming {
					display: flex;
					align-items: center;
					justify-content: space-around;
					min-height: 240px;
					margin-bottom: 60px;
				}

				.minter-infos {
					display: grid;
					grid-template-columns: repeat(3, auto);
					grid-gap: 50px;
					font-size: 18px;
					text-align: left;
					margin: 70px;

					@media (max-width: $screen-s) {
						margin: 20px;
					}

					@media (max-width: $screen-m) {
						grid-template-columns: repeat(2, auto);
					}

					.minter-cell {
						.minter-cell-title {
							font-family: var(--font-tertiary);
						}

						.minter-cell-value {
						}
					}
				}

				.mint-amount {
					display: flex;
					flex-direction: row;
					justify-content: center;
					gap: 24px;
					margin-bottom: 24px;

					.border {
						border: 0.5px solid var(--color-1);
						max-height: 24px;
						margin-top: 6px;
					}
				}

				.mint-button {
					align-self: center;
					margin-bottom: 48px;
				}
			}

			.minter-below {
				margin-top: 40px;
				display: grid;
				grid-template-columns: 227px auto;
				grid-gap: 24px;
				text-align: left;

				@media (max-width: $screen-m) {
					grid-template-columns: auto;
					text-align: center;

					img {
						margin: auto;
					}
				}

				p {
					margin: 0;
					font-family: var(--font-tertiary);
					font-weight: 400;
				}
			}
		}
	}
}

.collection-info {
	background-image: url("../../../assets/images/pages/home/background/collection-background.png");
	background-size: cover;
	min-height: 700px;
	margin-top: -200px;
	position: relative;

	@media (max-width: 1900px) {
		min-height: 500px;
		margin-top: -150px;
	}

	@media (max-width: $screen-s) {
		min-height: 780px;
	}

	.collection-info-container {
		color: var(--color-4);
		text-align: center;
		margin: -70px auto 100px auto;
		max-width: 1280px;
		padding: 200px 60px;
		display: block;

		@media (max-width: $screen-m) {
			padding: 100px 60px 200px 60px;
		}

		> h2 {
			font-family: var(--font-secondary);
			font-weight: 400;
			font-size: 40px;
			line-height: 44px;
			color: var(--color-5);
			margin-bottom: 20px;
		}

		> p {
			font-family: var(--font-secondary);
			font-weight: 400;
			font-size: 24px;
			line-height: 36px;
			color: var(--color-4);
			margin-bottom: 40px;
		}

		.collection-info-grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 64px;
			text-align: left;

			@media (max-width: $screen-m) {
				grid-template-columns: auto;
			}

			p {
				font-family: var(--font-secondary);
				font-weight: 400;
				font-size: 24px;
				line-height: 26px;
				color: var(--color-4);
				margin-bottom: 20px;
			}
		}

		.collection-info-pow {
			position: absolute;
			bottom: -90px;
			right: 300px;
			z-index: 1;

			@media (max-width: 1900px) {
				right: 200px;
			}

			@media (max-width: $screen-l) {
				right: 100px;
			}

			@media (max-width: $screen-m) {
				bottom: 40px;
				right: 0;
				width: 184px;
			}

			@media (max-width: $screen-s) {
				bottom: 140px;
				right: 0;
			}
		}
	}
}

.collection-samples {
	text-align: center;
	margin-top: 100px;

	> h2 {
		font-family: var(--font-secondary);
		font-weight: 400;
		font-size: 40px;
		line-height: 44px;
		color: var(--color-1);
		margin-bottom: 20px;
	}

	.collection-samples-grid {
		max-width: 1280px;
		margin: 0 auto;
		padding: 32px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 24px;

		@media (max-width: $screen-m) {
			grid-template-columns: repeat(2, 1fr);
		}

		img {
			width: 100%;
		}
	}
}

.advantages {
	padding: 0 30px;

	.advantages-container {
		position: relative;
		padding: 90px 70px 110px 70px;
		background-image: url("../../../assets/images/pages/home/background/advantages-background.png");
		background-position: center center;
		max-width: 1280px;
		margin: 160px auto;
		text-align: center;
		box-sizing: border-box;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 10px;

		> h2 {
			font-family: var(--font-secondary);
			font-weight: 400;
			font-size: 40px;
			line-height: 44px;
			color: var(--color-1);
			margin-bottom: 60px;
		}

		.advantages-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 50px;

			@media (max-width: $screen-m) {
				grid-template-columns: auto;
			}
		}

		.advantages-cell {
			background: linear-gradient(0deg, #f8e240, #f8e240), #f8e240;
			border: 4px solid var(--color-1);
			box-shadow: 8px 8px var(--color-1);
			padding: 24px;

			> h3 {
				font-family: var(--font-secondary);
				font-weight: 400;
				font-size: 24px;
				line-height: 26px;
			}

			p {
				font-size: 18px;
			}
		}

		.advantages-more {
			font-family: var(--font-secondary);
			font-weight: 400;
			font-size: 32px;
			line-height: 35px;
			text-align: center;
			margin: 50px auto 0px auto;
		}

		.advantages-best {
			font-family: var(--font-secondary);
			font-weight: 400;
			font-size: 18px;
			line-height: 24px;
			margin: 10px auto 0 auto;
		}
	}

	.lightning-br {
		position: absolute;
		bottom: -50px;
		right: 150px;
	}

	.lightning-tr {
		position: absolute;
		top: -60px;
		left: 30px;
	}

	.lightning-mr {
		position: absolute;
		bottom: 100px;
		right: 20px;

		@media (max-width: $screen-s) {
			bottom: 0px;
			right: 20px;
		}
	}
}

.roadmap {
	text-align: center;

	> h2 {
		font-family: var(--font-secondary);
		font-weight: 400;
		font-size: 40px;
		line-height: 44px;
		color: var(--color-1);
		margin-bottom: 60px;
	}

	> div {
		margin: auto;
		height: 300px;
		width: 1350px;
		background-image: url("../../../assets/images/pages/home/roadmap.svg");
		background-size: contain;
		background-repeat: no-repeat;

		@media (max-width: 1350px) {
			height: 200px;
			width: 900px;
		}

		@media (max-width: $screen-m) {
			background-image: url("../../../assets/images/pages/home/roadmap-mobile.svg");
			height: 920px;
			width: 400px;
		}
	}
}

.socials {
	background-image: url("../../../assets/images/pages/home/background/social-background.png");
	background-size: cover;
	background-repeat: no-repeat;
	min-height: calc(100vw / 3 + 50px);
	position: relative;
	margin-top: -260px;
	text-align: center;

	@media (max-width: $screen-l) {
		min-height: 670px;
	}

	@media (max-width: $screen-m) {
		min-height: 870px;
	}

	.socials-container {
		color: var(--color-4);
		text-align: center;
		margin: -70px auto 100px auto;
		max-width: 1280px;
		padding: 290px 0;
		display: block;

		@media (max-width: 1900px) {
			padding: 140px 0;
		}

		@media (max-width: 1400px) {
			padding: 100px 0;
		}

		> h2 {
			font-family: var(--font-secondary);
			font-weight: 400;
			font-size: 40px;
			line-height: 44px;
			color: var(--color-4);
			margin-bottom: 60px;
		}

		.socials-grid {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 50px;
			max-width: 1070px;
			margin: auto;

			@media (max-width: $screen-l) {
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 20px;
			}

			@media (max-width: $screen-m) {
				grid-template-columns: auto;
				grid-gap: 20px;
			}

			a > img {
				width: 228px;
				margin: auto;
			}
		}
	}
}

.partners {
	text-align: center;
	margin-top: 160px;

	.partners-container {
		color: var(--color-1);
		text-align: center;
		margin: auto;
		max-width: 1280px;
		display: block;

		> h2 {
			font-family: var(--font-secondary);
			font-weight: 400;
			font-size: 40px;
			line-height: 44px;
			color: var(--color-1);
			margin-bottom: 70px;
		}

		.partners-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 50px;
			max-width: 1280px;
			margin: auto;

			@media (max-width: 1200px) {
				grid-template-columns: auto;
			}
		}

		.partners-cell {
			h3 {
				font-family: var(--font-secondary);
				font-weight: 400;
				font-size: 24px;
				line-height: 26px;
				text-align: center;
				margin: 15px auto 0 auto;
			}

			.partners-logo {
				height: 338px;
				box-shadow: 8px 8px var(--color-1);
			}

			.partners-btn {
				margin-top: 10px;
				height: 44px;
			}
		}
	}
}

