@import "Themes/constants.scss";

.root {
	margin-top: 60px;
	background: var(--color-7);
	padding: 62px;

	.root-container {
		color: var(--color-1);
		margin: 0 auto;
		box-sizing: border-box;
		max-width: 1280px;
		display: block;
		display: grid;
		grid-template-columns: 80px auto 170px;
		text-align: center;

		> img {
			margin: auto;
		}

		@media (max-width: $screen-m) {
			grid-template-columns: auto;
		}

		.root-text {
			font-family: var(--font-secondary);
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 40px;
		}

		.root-right {
			margin: 10px auto 0 auto;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 24px;
			max-width: 170px;
		}

		> img {
			width: 80px;
		}
	}
}
