@import "Themes/constants.scss";

.root {
	position: absolute;
	z-index: 5;
	width: 100%;
	display: grid;
	grid-template-columns: auto auto auto;
	margin-top: 16px;

	.left {
		.logo {
			width: 94px;
			margin-left: 32px;
		}
	}

	.right {
		float: right;
	}
}

