@import "Themes/constants.scss";

.root {
	display: flex;
	align-items: center;
	user-select: none;

	.input {
		border: none;
		width: 36px;
		height: 36px;

		font-family: var(--font-primary);
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 36px;
		letter-spacing: 0.25px;
		color: var(--color-1);

		display: flex;
		justify-content: center;
		pointer-events: none;
		text-align: center;
	}

	.button {
		cursor: pointer;
	}
}

