:root {
	--content-max-width: 1440px;

	--font-primary: "Roboto";
	--font-secondary: "Road Rage";
	--font-tertiary: "Brightness Book";

	--color-background-body: #ffffff;

	--color-1: #111827;
	--color-2: #FFF500;
	--color-3: #000000;
	--color-4: #FFFFFF;
	--color-5: #F4C60F;
	--color-6: #F8E240;
	--color-7: #EEEEEE;
	--color-8: #FF0000;
	--color-9: #000000;
	--color-10: #000000;
}

