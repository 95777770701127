@import "Themes/constants.scss";
@import "Themes/fonts.scss";
@import "Themes/variables.scss";

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-background-body);
	font-family: var(--font-primary);
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
