.root {
	border-radius: 8px;
	border: none;
	cursor: pointer;
	font-family: var(--font-primary);
	box-sizing: border-box;
	white-space: nowrap;
	font-weight: bold;
	height: fit-content;
	user-select: none;
	min-width: 200px;
	
	&[variant="primary"] {
		color: var(--color-1);
		background-color: var(--color-2);

		&:hover {
			opacity: 0.8;
		}

		&:active {
			opacity: 0.6;
		}

		&:disabled {
			background-color: rgba(255, 255, 255, 0.1);
			opacity: 0.3;
			color: var(--color-1);
			pointer-events: none;
		}
	}

	&[variant="secondary"] {
		border: 1px solid var(--color-4);
		color: var(--color-4);
		background-color: rgba(0, 0, 0, 0);
		
		&:hover {
			opacity: 0.8;
		}

		&:active {
			opacity: 0.6;
		}

		&:disabled {
			opacity: 0.3;
		}
	}

	&[sizing="s"] {
		font-size: 12px;
		line-height: 18px;
		padding: 6px 12px;
	}

	&[sizing="m"] {
		font-size: 16px;
		line-height: 28px;
		padding: 8px 24px;
	}

	&[sizing="l"] {
		font-size: 18px;
		line-height: 24px;
		padding: 12px 18px;
	}

	&[sizing="xl"] {
		font-size: 24px;
		line-height: 28px;
		padding: 12px 32px;
	}

	&[sizing="xxl"] {
		font-weight: 500;
		font-size: 18px;
		line-height: 30px;
		padding: 15px 135px;
	}
}
