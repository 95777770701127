@import "Themes/constants.scss";

.root {
	display: inline-block;

	.legend-text {
		display: inline-block;
		font-family: var(--font-tertiary);
		color: var(--color-1);
		line-height: 36px;
	}

	.price-text {
		display: inline-block;
		font-family: var(--font-primary);
		color: var(--color-1);
		line-height: 36px;
        margin-left: 8px;
	}
}

