.root {
	font-style: var(--font-primary);
	padding: 0 5px;
	font-weight: 300;
	font-size: 18px;
	font-weight: bold;
	line-height: 24px;
	margin-right: 10px;
	color: var(--color-background-body);
}
